import React from 'react';
import 'styles/MyViews.css';

function MyViews() {
    return (
        <div className='MyViews'>
            <h1>Society Speaks</h1>
            <p>My Perspectives Unveiling Soon!</p>
        </div>
    );
}

export default MyViews;